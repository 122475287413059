/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './CategoryTree.scss';
import {classes as optionClasses, cssStates} from './CategoryOption.st.css';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {TextButton, Text} from 'wix-ui-tpa/cssVars';
import {ConditionalRender} from '../ConditionalRender/ConditionalRender';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {Experiments} from '../../../constants';
import {ICategory, ICategoryListItem} from '../../../types/category';
import {useCategories} from '../../hooks/useCategories';

export const CategoryTreeDataHook = {
  Title: 'category-tree-title',
  Content: 'category-tree-content',
  Container: 'category-tree-container',
  CategoryOption: {
    at: (i: number) => `category-option-${i}`,
  },
};

export const CategoryTree: React.FunctionComponent<IGalleryGlobalProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(({t, globals}: IGalleryGlobalProps & IProvidedTranslationProps) => {
    const htmlTag = globals.htmlTags.categoriesFiltersHtmlTag;
    const {experiments} = useExperiments();
    let categories: ICategoryListItem[], currentCategory: ICategory;
    if (experiments.enabled(Experiments.NewCategoryList)) {
      const {categoryList, selectedCategory} = useCategories();
      categories = categoryList;
      currentCategory = selectedCategory;
    } else {
      categories = globals.categories;
      currentCategory = globals.currentCategory;
    }

    const onCategoryClicked = (e, destinationCategoryId: string, destinationLink: string) => {
      const {handleCategoryClick} = globals;
      e.preventDefault();
      handleCategoryClick({destinationCategoryId, destinationLink});
    };

    return (
      <div className={s.container} data-hook={CategoryTreeDataHook.Container}>
        <ConditionalRender by={'gallery_showCategoriesTitle'}>
          {experiments.enabled(Experiments.GalleryA11yFixes) ? (
            <Text data-hook={CategoryTreeDataHook.Title} className={s.title} tagName={htmlTag}>
              {t('categoryTreeTitle')}
            </Text>
          ) : (
            <h2 data-hook={CategoryTreeDataHook.Title} className={s.title}>
              {t('categoryTreeTitle')}
            </h2>
          )}
        </ConditionalRender>
        <div data-hook={CategoryTreeDataHook.Content} className={s.content}>
          <ul className={s.options}>
            {categories.map((category, i) => (
              <li key={category.id} className={s.option}>
                <TextButton
                  data-hook={CategoryTreeDataHook.CategoryOption.at(i)}
                  as="a"
                  className={`${optionClasses.option} ${cssStates({
                    activeCategory: currentCategory.id === category.id,
                  })}`}
                  priority={'secondary' as any}
                  href={category.categoryUrl}
                  onClick={(event) => onCategoryClicked(event, category.id, category.categoryUrl)}>
                  {category.name === 'All Products' ? t('allProducts') : category.name}
                </TextButton>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  })
);
